<template>
  <div class="goods-return">
    <el-breadcrumb>
      <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item>商品退换</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <h3>商品退换</h3>
      <div class="goods-list">
        <img src="../../assets/logo.png" alt="">
        <p>您还没有退换记录！</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'goodsReturn'
}
</script>

<style scoped lang="scss">
@import "src/scss/index";
.goods-return {
  @include body;
  .goods-list {
    @include flexCenter($direction: column, $justify: unset)
  }
}
</style>
